import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { resetStores } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoleType } from '@nl/email/app/shared/model/auth.model';
import { AuthService } from '@nl/email/app/shared/service/auth/auth.service';
import { getApi } from '@nl/email/app/shared/utils/get-api';
import { when } from '@nl/utils';
import { isEqual } from 'lodash-es';
import { eq } from 'lodash/fp';
import { BehaviorSubject } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { AccountInfo, UserStatus } from './account.model';
import { AccountStore } from './account.store';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AccountService implements OnDestroy {
  constructor(
    private account: AccountStore,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  account$ = new BehaviorSubject<AccountInfo['account']>('');

  logout() {
    this.reset();
    this.authService.logout();
  }

  reset() {
    resetStores({ exclude: ['layout'] });
  }

  fetchAccountInfo() {
    return this.http.get<AccountInfo>(getApi('/account/expired/info')).pipe(
      tap(info => this.account$.next(info.account)),
      tap(info => this.updateAccountInfo(info)),
      untilDestroyed(this)
    );
  }

  refreshToken() {
    return this.http.get(getApi('/account/expired/refresh')).pipe(share());
  }

  updateAccountInfo(accountInfo: AccountInfo) {
    const {
      sn,
      type,
      account,
      expiredDate,
      status,
      roles,
      isNewsleopardActive,
      isSmsActive,
      remaining,
      smsRemaining,
      emailQuotaThreshold,
      smsQuotaThreshold,
      domainAuthenticationStatus,
      mfaEnabled = false,
    } = accountInfo;
    when(eq(UserStatus.BANNED), _ => this.logout())(status);
    this.account.update({
      sn,
      type,
      account,
      expiredDate,
      roles,
      isNewsleopardActive,
      isSmsActive,
      remaining,
      smsRemaining,
      emailQuotaThreshold,
      smsQuotaThreshold,
      domainAuthenticationStatus,
      mfaEnabled,
    });
  }

  getAccountInfo() {
    this.fetchAccountInfo().subscribe();
  }

  updateRoles(roles: RoleType[]) {
    const currentRoles = this.account._value().roles;
    if (isEqual(roles, currentRoles)) {
      return;
    }

    this.account.update({ roles });
  }

  ngOnDestroy() {}
}
